import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { TOKEN_REFRESH } from "@app/api/mobile/send"
import { checkTokenExpiration } from "@app/api/useFetchInterceptor"
import * as ErrorHandler from "@app/errorHandler"
import { isWhitespaceString } from "@app/lib/regexUtils"
import { Logger } from "@app/model"
import { Message } from "@app/model/chat"
import { format as dateFormat } from "@app/model/date"
import { getAccessToken } from "@app/utils"
import cn from "classnames"
import { Option, pipe, ReadonlyArray } from "effect"
import Linkify from "linkify-react"
import * as LiveSwitchApp from "liveswitch"
import { v4 as uuidv4 } from "uuid"

import * as APIError from "api/error"
import { Icons } from "assets"

import { useWebSocketsContext } from "../WebSocketsProvider"

const generateCannedMessage = (timestamp = Date.now()): Message => {
  return {
    id: uuidv4(),
    text: "Hi, let us know how we can assist you while we connect you to a live agent.",
    timestamp,
    user: "auto-message",
    alias: "AGENT",
  }
}

const toMessages = (messageHistory: Message[]): Message[] => {
  return pipe(
    messageHistory,
    ReadonlyArray.head,
    Option.map(({ timestamp }) => generateCannedMessage(timestamp)),
    Option.match({
      onNone: () => messageHistory,
      onSome: initialCannedMessage => [initialCannedMessage, ...messageHistory],
    }),
  )
}

const Chat = (): JSX.Element => {
  const [currentMessage, setCurrentMessage] = useState<string>("")
  const [messages, setMessages] = useState<Message[]>([])
  const [isSending, setIsSending] = useState<boolean>(false)
  const { sendMessage, message } = useWebSocketsContext()

  useEffect(() => {
    const loadMessagesHistory = async (): Promise<void> => {
      try {
        let nextMessages
        const messageHistory = await LiveSwitchApp.getChatHistory()
        nextMessages = toMessages(messageHistory)
        setMessages(nextMessages)
        if (nextMessages.length === 0) {
          const cannedMessage = generateCannedMessage()
          nextMessages = [cannedMessage]
          setMessages(nextMessages)
        }
      } catch (error) {
        Logger.log(TOKEN_REFRESH)
        ErrorHandler.captureException(error)
      }
    }

    void loadMessagesHistory()
  }, [])

  useEffect(() => {
    pipe(
      message,
      Option.map(incomingMessage => {
        return setMessages(prevMessages =>
          incomingMessage.data.sender === "AGENT"
            ? [
                ...prevMessages,
                {
                  id: uuidv4(),
                  text: incomingMessage.data.payload,
                  timestamp: parseInt(incomingMessage.data.timestamp, 10),
                  user: incomingMessage.data.id,
                  alias: "AGENT",
                } as unknown as Message,
              ]
            : prevMessages,
        )
      }),
    )
  }, [message])

  const handleOnSendChat = (): void => {
    try {
      setIsSending(true)
      if (!isWhitespaceString(currentMessage)) {
        const checkToken = checkTokenExpiration(getAccessToken())
        if (checkToken) {
          sendMessage(currentMessage)
          const newMessage: Message = {
            id: uuidv4(),
            text: currentMessage,
            timestamp: Date.now(),
            user: LiveSwitchApp.client.getId(),
            alias: "USER",
          }
          setMessages(prevMessages => [...prevMessages, newMessage])
        }
      }
    } catch (e) {
      Logger.log("TOKEN_REFRESH")
      setTimeout(() => {
        handleOnSendChat()
      }, 2000)
      throw APIError.Unauthorized
    }
    setIsSending(false)
    setCurrentMessage("")
  }

  const handleValidationToSendChat = (): void => {
    if (!isSending) {
      handleOnSendChat()
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const nextCurrentMessage = event.target.value
    setCurrentMessage(nextCurrentMessage)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter" && !isSending) {
      handleOnSendChat()
    }
  }

  return (
    <div className="flex flex-col flex-1 h-1 items-center justify-between bg-slate-900">
      <Messages messages={messages} />

      <div className="flex space-x-2 items-center justify-between w-full p-4 pt-0 box-border">
        <input
          className="font-sans w-full appearance-none bg-slate-900 border border-emerald-300 rounded-full h-[42px] py-0 px-3 text-emerald-100 leading-tight text-base focus:border-emerald-300 focus:outline-none"
          type="text"
          value={currentMessage}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />

        <div
          className="flex cursor-pointer border-solid border-emerald-300 border p-[9px] active:opacity-60 rounded-full"
          onClick={handleValidationToSendChat}
        >
          <Icons.PaperAirplane className="w-6 h-6" />
        </div>
      </div>
    </div>
  )
}

type MessagesProps = {
  messages: Message[]
}
const Messages = ({ messages }: MessagesProps): JSX.Element => {
  const { t } = useTranslation()
  const messagesRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = (): void => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const firstMessageTime: Option.Option<string> = pipe(
    messages,
    ReadonlyArray.head,
    Option.map(({ timestamp }) => dateFormat(timestamp)),
  )

  return (
    <div
      className="flex flex-col space-y-2 w-full h-full overflow-auto scroll-smooth px-4 box-border pb-4"
      ref={messagesRef}
    >
      <div className="flex flex-row pt-6 items-center px-4">
        <Icons.Info className="w-14 h-14" />
        <div className="text-base text-left text-emerald-100 pl-4 leading-5">
          {t("emergency_disclaimer")}
        </div>
      </div>
      {pipe(
        firstMessageTime,
        Option.match({
          onNone: () => <></>,
          onSome: time => (
            <div className="text-xs text-emerald-100 tracking-wide text-center pt-6 pb-3">
              {time}
            </div>
          ),
        }),
      )}

      {messages.map(message => (
        <MessageItem key={message.id} message={message} />
      ))}
    </div>
  )
}

const MessageItem = ({ message }: { message: Message }): JSX.Element => {
  const isMineMessage = LiveSwitchApp.isMessageFromMe(message)

  const options = {
    className:
      "text-sm tracking-wide overflow-hidden break-words hyphens-auto text-emerald-100",
  }
  return (
    <div
      className={cn("flex max-w-[80%] items-center space-x-2", {
        "self-end": isMineMessage,
        "self-start": !isMineMessage,
      })}
    >
      <div
        className={cn(
          "flex-1 rounded-3xl p-4 text-xl leading-6 tracking-wide overflow-hidden break-normal",
          {
            "bg-emerald-700 text-emerald-100 rounded-br-sm": isMineMessage,
            "bg-slate-950 text-emerald-100 rounded-bl-sm": !isMineMessage,
          },
        )}
      >
        <Linkify options={options}>{message.text}</Linkify>
      </div>
    </div>
  )
}

export default Chat
