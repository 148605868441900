import * as Utils from "../utils"

export type AuthorizationHeader = {
  Authorization: string
}
const getAuthorizationHeader = (): AuthorizationHeader => {
  const token = Utils.getAccessToken()
  return {
    Authorization: `Bearer ${token}`,
  }
}

export type RequestMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE"

type RequestBodyData =
  | Record<string, unknown>
  | Record<string, unknown>[]
  | string
  | string[]

type FetchWithAuthParams = {
  path: string
  method: RequestMethod
  body?: RequestBodyData
  extraHeaders?: Record<string, unknown>
}
export const fetchWithAuth = async (
  params: FetchWithAuthParams,
): Promise<Response> => {
  const { path, method, body, extraHeaders } = params

  const REST_URL = Utils.getRestURL()
  const endpoint = `${REST_URL}/${path}`
  const authHeader = getAuthorizationHeader()
  const headers = {
    ...authHeader,
    "Content-Type": "application/json",
    ...extraHeaders,
  }

  return await fetch(endpoint, {
    headers,
    method,
    body: JSON.stringify(body),
  })
}
