import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"
import * as API from "@app/api/api"
import { checkTokenExpiration } from "@app/api/useFetchInterceptor"
import * as ErrorHandler from "@app/errorHandler"
import { Logger } from "@app/model"
import { getAccessToken, getWebSocketsURL } from "@app/utils"
import * as S from "@effect/schema/Schema"
import { Option, pipe } from "effect"
import * as LiveSwitchApp from "liveswitch"

type ConnectionStatus =
  | "Connecting"
  | "Open"
  | "Closing"
  | "Closed"
  | "Uninstantiated"

const toConnectionStatus = (readyState: ReadyState): ConnectionStatus => {
  switch (readyState) {
    case ReadyState.CONNECTING:
      return "Connecting"
    case ReadyState.OPEN:
      return "Open"
    case ReadyState.CLOSING:
      return "Closing"
    case ReadyState.CLOSED:
      return "Closed"
    case ReadyState.UNINSTANTIATED:
      return "Uninstantiated"
  }
}

type OutcomeMessage = {
  type: "SEND_MESSAGE"
  data: {
    sender: "AGENT" | "USER"
    payload: string
    clientId: string
    deviceId: string
    channelId?: string
    timestamp?: string
  }
}

type IncomeMessage = {
  type: "INCOMING_MESSAGE"
  data: {
    id: number
    sender: "AGENT" | "USER"
    payload: string
    timestamp: string
  }
}

export type AuthorizationHeader = {
  Authorization: string
}

type WebSocketsContextState = {
  sendMessage: (message: string) => void
  message: Option.Option<IncomeMessage>
  status: ConnectionStatus
}

const WebSocketsContext = createContext<WebSocketsContextState | undefined>(
  undefined,
)

type WebSocketsProviderProps = {
  children: ReactNode
}

export const buildOutcommingMessage = ({
  message,
}: {
  message: string
}): OutcomeMessage => ({
  type: "SEND_MESSAGE",
  data: {
    sender: "USER",
    payload: message,
    clientId: LiveSwitchApp.client.getId(),
    deviceId: LiveSwitchApp.client.getDeviceId(),
    channelId: LiveSwitchApp.channel?.getId(),
    timestamp: `${Date.now()}`,
  },
})

const IncomeMessageSchema: S.Schema<IncomeMessage> = S.struct({
  type: S.literal("INCOMING_MESSAGE"),
  data: S.struct({
    id: S.number,
    sender: S.literal("AGENT", "USER"),
    payload: S.string,
    timestamp: S.string,
  }),
})

export const decodeIncomeMessage = (
  message: unknown,
): Option.Option<IncomeMessage> => {
  const result = S.decodeUnknownEither(IncomeMessageSchema)(message)

  if (result._tag === "Right") {
    const incomeMessage = result.right
    Logger.log(`Decoded message: ${JSON.stringify(incomeMessage)}`)
    return Option.some(incomeMessage)
  } else {
    const parseError = result.left
    ErrorHandler.captureException(
      `Failed to decode WebSocket incoming message.\nMessage: ${JSON.stringify(
        message,
      )}.\nError: ${parseError.message}`,
    )
    return Option.none()
  }
}

export const WebSocketsProvider = ({
  children,
}: WebSocketsProviderProps): JSX.Element => {
  const [auth, setAuth] = useState<Option.Option<API.AuthorizationHeader>>(
    Option.none(),
  )
  const [websocketURL, setWebsocketURL] = useState<string>("")

  const shouldConnectToSocket = Option.isSome(auth) && Boolean(websocketURL)
  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketURL,
    {
      share: true,
      shouldReconnect: _closeEvent => true,
      onClose: e => Logger.log(`Closed ${JSON.stringify(e)}`),
      onOpen: () => Logger.log("Open"),
      onError: e => Logger.log(`Error ${JSON.stringify(e)}`),
      retryOnError: true,
    },
    shouldConnectToSocket,
  )

  const status: ConnectionStatus = useMemo(
    () => toConnectionStatus(readyState),
    [readyState],
  )

  const message: Option.Option<IncomeMessage> = useMemo(() => {
    if (lastJsonMessage) {
      return pipe(lastJsonMessage, decodeIncomeMessage)
    } else {
      return Option.none()
    }
  }, [lastJsonMessage])

  const determineSocketAuth = useCallback(() => {
    const checkToken = checkTokenExpiration(getAccessToken())
    if (checkToken) {
      const nextAuth = {
        Authorization: `Bearer ${checkToken}`,
      }
      setAuth(Option.some(nextAuth))
      setWebsocketURL(
        `${getWebSocketsURL()}/live-agent/api/v2/chat/ws?authorization_token=Bearer ${checkToken}`,
      )
    } else {
      throw new Error("Unauthorized")
    }
  }, [setAuth])

  useEffect(() => {
    determineSocketAuth()
  }, [determineSocketAuth])

  useEffect(() => {
    if (status === "Closed") {
      determineSocketAuth()
    }
  }, [determineSocketAuth, status])

  const handleOnSendMessage = useCallback(
    (outcomeMessage: string) => {
      const nextMessage = JSON.stringify(
        buildOutcommingMessage({ message: outcomeMessage }),
      )
      determineSocketAuth()
      sendMessage(nextMessage)
    },
    [sendMessage, determineSocketAuth],
  )

  return (
    <WebSocketsContext.Provider
      value={{
        sendMessage: handleOnSendMessage,
        message,
        status,
      }}
    >
      {children}
    </WebSocketsContext.Provider>
  )
}

export const useWebSocketsContext = (): WebSocketsContextState => {
  const context = useContext(WebSocketsContext)

  if (context === undefined) {
    throw new Error("WebSocketsContext must be used with a Provider")
  }

  return context
}
